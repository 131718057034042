const colors = {
  midgray: "#7F7F7F",
  darkgray: "#454545",
  availabilityRed: "#C9022E",
  bgRed: "#FCCAC6",
  availabilityGreen: "#049C54",
  bgGreen: "#CBE7D2",
  primary: {
    50: "#F6E1ED",
    100: "#ECC3DB",
    200: "#E3A5CA",
    300: "#D987B8",
    400: "#D069A6",
    500: "#C64B94",
    600: "#A53F7B",
    700: "#843263",
    800: "#63264A",
    900: "#421931",
  },

  urgent: {
    50: "#F36D63",
    100: "#F25D52",
    200: "#F14D41",
    300: "#EF3C30",
    400: "#EE2C1E",
    500: "#e82011",
    600: "#C71B0F",
    700: "#A6170C",
    800: "#85120A",
    900: "#640E07",
  },

  complete: {
    50: "#D5EFE3",
    100: "#ABDEC6",
    200: "#82CEAA",
    300: "#58BD8D",
    400: "#2EAD71",
    500: "#049C54",
    600: "#038246",
    700: "#036838",
    800: "#024E2A",
    900: "#011A0E",
  },

  required: {
    50: "#FBAA5F",
    100: "#FAA14D",
    200: "#FA983C",
    300: "#FA8E2A",
    400: "#F98518",
    500: "#f97a07",
    600: "#D56A05",
    700: "#B25805",
    800: "#8E4704",
    900: "#6B3503",
  },

  purple: {
    50: "#F2F1F4",
    100: "#D7D5DE",
    200: "#BDB9C8",
    300: "#7B7491",
    400: "#5A5176",
    500: "#392E5A",
    600: "#30264B",
    700: "#261F3C",
    800: "#1D172D",
    900: "#130F1E",
  },

  jobs: {
    hca: "#D885ED",
    mapa: "#000000",
    qualified_sw: "#000000",
    nurse: "#CFDCD0",
    shca: "#9679ED",
    support: "#F0DC00",
    interim_manager: "#7090D1",
    locum_gp: "#DB8D35",
  },
};

export default colors;
